import React, { useEffect } from 'react';
import Header from './Header/Header';
import { CalendarViewModel } from '../ViewModel/viewModel';
import { CalendarActions } from '../Actions/actions';
import { useEnvironment, WidgetProps } from 'yoshi-flow-editor';
import { classes, st } from './Widget.st.css';
import EmptyState from './EmptyState/EmptyState';
import { BottomSectionStatus } from '../ViewModel/widgetViewModel/widgetViewModel';
import settingsParams from '../settingsParams';
import { useSettings } from 'yoshi-flow-editor/tpa-settings/react';
import SectionHeader from './SectionHeader/SectionHeader';
import TimePicker from './TimePicker/TimePicker';
import DatePicker from './DatePicker/DatePicker';
import { Spinner } from 'wix-ui-tpa/Spinner';
import { CalendarActionsContext } from '../Actions/actionsContext';
import BookingDetails from './BookingDetails/BookingDetails';

export interface ControllerProps extends CalendarActions, CalendarViewModel {}

const Widget: React.FC<WidgetProps<ControllerProps>> = (props) => {
  const settings = useSettings();
  const { isMobile } = useEnvironment();

  useEffect(() => void props.initializeWidgetAction(), []);

  const getWidgetLoader = () => (
    <div
      data-hook="widget-loader"
      className={st(classes.loader, {
        isMobile,
      })}
    >
      <Spinner diameter={50} isCentered={true} />
    </div>
  );

  const getWidgetContentByStatus = () => {
    switch (props.widgetViewModel.status) {
      case BottomSectionStatus.LOADING:
        return getWidgetLoader();
      case BottomSectionStatus.NO_SERVICE_AVAILABILITY: {
        const { title, subtitle } = props.noAvailabilityViewModel;
        return <EmptyState title={title} subtitle={subtitle} />;
      }
      case BottomSectionStatus.LOADED:
        return <BottomSectionContent {...props} />;
      default:
        return getWidgetLoader();
    }
  };

  return (
    <CalendarActionsContext.Provider value={props}>
      <div
        className={st(classes.root, {
          textAlignment: settings.get(settingsParams.textAlignment),
          columnAlignment: settings.get(settingsParams.columnAlignment),
        })}
        data-hook="BookingCalendar-wrapper"
      >
        <div data-hook="top-section" className={classes.topSection}>
          <div
            data-hook="top-section-content"
            className={classes.topSectionContentContainer}
          >
            <Header viewModel={props.headerViewModel} />
          </div>
        </div>
        <div data-hook="bottom-section" className={classes.bottomSection}>
          <div
            data-hook="bottom-section-content"
            className={classes.bottomSectionContentContainer}
          >
            {getWidgetContentByStatus()}
          </div>
        </div>
      </div>
    </CalendarActionsContext.Provider>
  );
};

const BottomSectionContent: React.FC<ControllerProps> = ({
  widgetViewModel,
  timePickerViewModel,
  bookingDetailsViewModel,
  datePickerViewModel,
}) => {
  const {
    dateAndTimeSectionHeaderText,
    bookingDetailsSectionHeaderText,
  } = widgetViewModel;
  return (
    <div className={classes.bottomSectionContent}>
      <div data-hook="date-time-section" className={classes.dateAndTimeSection}>
        <SectionHeader title={dateAndTimeSectionHeaderText} />
        <div className={classes.dateAndTimeSectionBody}>
          <div className={classes.dateSubSection}>
            <DatePicker datePickerViewModel={datePickerViewModel} />
          </div>
          <div className={classes.timeSubSection}>
            <TimePicker viewModel={timePickerViewModel} />
          </div>
        </div>
      </div>
      <div
        data-hook="booking-details-section"
        className={classes.bookingDetailsSection}
      >
        <SectionHeader title={bookingDetailsSectionHeaderText} />
        <div className={classes.bookingDetailsSectionBody}></div>
        <div className={classes.dateAndTimeSectionBody}>
          <BookingDetails viewModel={bookingDetailsViewModel} />
        </div>
      </div>
    </div>
  );
};

export default Widget;
