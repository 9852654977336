import React from 'react';
import { Text } from 'wix-ui-tpa/Text';
import { classes } from './SectionHeader.st.css';
import { AccessibilityHtmlTags } from '../../../../utils/AccessibilityHtmlTags.const';
import { Divider } from 'wix-ui-tpa/Divider';

export type SectionHeaderProps = {
  title: string;
};

const SectionHeader: React.FC<SectionHeaderProps> = ({ title }) => {
  return (
    <>
      <Text
        data-hook="section-header-title"
        className={classes.title}
        tagName={AccessibilityHtmlTags.SecondaryHeading}
      >
        {title}
      </Text>
      <Divider className={classes.divider} data-hook="section-header-divider" />
    </>
  );
};

export default SectionHeader;
