import { Text } from 'wix-ui-tpa/Text';
import { classes, st } from './BookingDetails.st.css';
import { AccessibilityHtmlTags } from '../../../../utils/AccessibilityHtmlTags.const';
import React, { useContext, useState } from 'react';
import { Badge, BADGE_PRIORITY } from 'wix-ui-tpa/Badge';
import { ReactComponent as CameraSVG } from 'wix-ui-tpa/dist/src/assets/icons/Camera.svg';
import { useEnvironment } from 'yoshi-flow-editor';
import { BookingDetailsDataHooks } from './BookingDetailsDataHooks';
import {
  BookingDetailsViewModel,
  SelectableOption,
} from '../../ViewModel/bookingDetailsViewModel/bookingDetailsViewModel';
import { Divider } from 'wix-ui-tpa/Divider';
import { Dropdown, DropdownOptionProps } from 'wix-ui-tpa/Dropdown';
import { CalendarActionsContext } from '../../Actions/actionsContext';
import { TextButton } from 'wix-ui-tpa/TextButton';

export interface BookingDetailsProps {
  viewModel: BookingDetailsViewModel;
}

const getDropDownSubtitle = (dropdownSubtitle: string) => {
  return {
    id: dropdownSubtitle,
    value: dropdownSubtitle,
    isSelectable: false,
    isSectionTitle: true,
  };
};

export const mapSelectableOptionsToDropdownOptions = (
  selectableOptions: SelectableOption[],
  subtitle: string,
): DropdownOptionProps[] => {
  const dropdownOptions: DropdownOptionProps[] = selectableOptions.map(
    (selectableOption) => {
      return {
        id: selectableOption.id,
        value: selectableOption.value,
        isSelectable: selectableOption.isSelectable,
        isSectionTitle: false,
      };
    },
  );
  if (selectableOptions.length > 1) {
    const dropdownSubtitle = getDropDownSubtitle(subtitle);
    dropdownOptions.unshift(dropdownSubtitle);
  }

  return dropdownOptions;
};

const SlotDetails: React.FC<BookingDetailsProps> = ({ viewModel }) => {
  const {
    serviceName,
    dateAndTime,
    paymentDescription,
    selectableLocations,
    selectableDurations,
    selectableStaffMembers,
    videoConferenceBadgeText,
  } = viewModel;

  return (
    <>
      <div
        data-hook={BookingDetailsDataHooks.SLOT_DETAILS}
        className={st(classes.serviceNameAndDate)}
      >
        {videoConferenceBadgeText && (
          <Badge
            data-hook={BookingDetailsDataHooks.VIDEO_CONFERENCE_BADGE}
            priority={BADGE_PRIORITY.light}
            icon={<CameraSVG />}
            className={st(classes.videoConferenceBadge)}
          >
            {videoConferenceBadgeText}
          </Badge>
        )}
        <Text
          data-hook={BookingDetailsDataHooks.SLOT_NAME}
          className={st(classes.commonTitleStyles)}
          tagName={AccessibilityHtmlTags.Paragraph}
        >
          {serviceName}
        </Text>
        <Text
          data-hook={BookingDetailsDataHooks.SLOT_DATE_AND_TIME}
          className={st(classes.commonTitleStyles)}
          tagName={AccessibilityHtmlTags.Paragraph}
        >
          {dateAndTime}
        </Text>
      </div>
      {selectableLocations.length === 1 ? (
        <Text
          data-hook={BookingDetailsDataHooks.SLOT_LOCATION}
          className={st(classes.commonTextStyle)}
          tagName={AccessibilityHtmlTags.Paragraph}
        >
          {selectableLocations[0].id}
        </Text>
      ) : null}
      {selectableStaffMembers.length === 1 ? (
        <Text
          data-hook={BookingDetailsDataHooks.SLOT_STAFF_MEMBER}
          className={st(classes.commonTextStyle)}
          tagName={AccessibilityHtmlTags.Paragraph}
        >
          {selectableStaffMembers[0].id}
        </Text>
      ) : null}
      {selectableDurations.length === 1 ? (
        <Text
          data-hook={BookingDetailsDataHooks.SLOT_DURATION}
          className={st(classes.commonTextStyle)}
          tagName={AccessibilityHtmlTags.Paragraph}
        >
          {selectableDurations[0].id}
        </Text>
      ) : null}
      <Text
        data-hook={BookingDetailsDataHooks.SLOT_PLAN_TYPE}
        className={st(classes.commonTextStyle)}
        tagName={AccessibilityHtmlTags.Paragraph}
      >
        {paymentDescription}
      </Text>
    </>
  );
};
const SlotPreferences: React.FC<BookingDetailsProps> = ({ viewModel }) => {
  const { onOptionSelectedAction } = useContext(CalendarActionsContext);
  const randomId = () => {
    return Math.random().toString(36).substring(7);
  };
  const [selectableStaffMembersKey, setSelectableStaffMembersKey] = useState(
    randomId(),
  );
  const [selectableLocationsKey, setSelectableLocationsKey] = useState(
    randomId(),
  );
  const [selectableDurationsKey, setSelectableDurationsKey] = useState(
    randomId(),
  );
  const initialiseDropDowns = () => {
    setSelectableStaffMembersKey(randomId());
    setSelectableLocationsKey(randomId());
    setSelectableDurationsKey(randomId());
    onOptionSelectedAction({
      location: '',
      duration: '',
      staffMember: '',
    });
  };

  const {
    selectableLocations,
    selectableDurations,
    selectableStaffMembers,
    bookingDetailsPreferencesHeaderText,
    bookingDetailsStaffMemberDropDownText,
    bookingDetailsLocationDropDownText,
    bookingDetailsDurationDropDownText,
    bookingDetailsClearText,
  } = viewModel;

  const selectableLocationsWithSubtitle = mapSelectableOptionsToDropdownOptions(
    selectableLocations,
    bookingDetailsLocationDropDownText,
  );
  const selectableStaffMembersWithSubtitle = mapSelectableOptionsToDropdownOptions(
    selectableStaffMembers,
    bookingDetailsStaffMemberDropDownText,
  );
  const selectableDurationsWithSubtitle = mapSelectableOptionsToDropdownOptions(
    selectableDurations,
    bookingDetailsDurationDropDownText,
  );

  return (
    <div data-hook={BookingDetailsDataHooks.SLOT_PREFERENCES_WRAPPER}>
      <Divider
        className={classes.divider}
        data-hook={BookingDetailsDataHooks.DEVIDER}
      />
      <div className={st(classes.preferencesWrapper)}>
        <Text
          data-hook={BookingDetailsDataHooks.SLOT_PREFERENCES_TITLE}
          className={st(classes.commonTitleStyles)}
          tagName={AccessibilityHtmlTags.Paragraph}
        >
          {bookingDetailsPreferencesHeaderText}
        </Text>
        <TextButton
          data-hook={BookingDetailsDataHooks.SLOT_CLEAR_TITLE}
          className={st(classes.commonTitleStyles)}
          onClick={() => initialiseDropDowns()}
        >
          {bookingDetailsClearText}
        </TextButton>
      </div>
      {selectableStaffMembers.length > 1 ? (
        <Dropdown
          upgrade
          data-hook={BookingDetailsDataHooks.SLOT_STAFF_MEMBER_DROPDOWN}
          className={st(classes.commonDropDownStyle)}
          placeholder={bookingDetailsStaffMemberDropDownText}
          options={selectableStaffMembersWithSubtitle}
          key={selectableStaffMembersKey}
          onChange={(staffMember) =>
            onOptionSelectedAction({
              staffMember: staffMember.id,
            })
          }
        />
      ) : null}
      {selectableLocations.length > 1 ? (
        <Dropdown
          upgrade
          data-hook={BookingDetailsDataHooks.SLOT_LOCATION_DROPDOWN}
          className={st(classes.commonDropDownStyle)}
          placeholder={bookingDetailsLocationDropDownText}
          options={selectableLocationsWithSubtitle}
          key={selectableLocationsKey}
          onChange={(location) =>
            onOptionSelectedAction({
              location: location.id,
            })
          }
        />
      ) : null}
      {selectableDurations.length > 1 ? (
        <Dropdown
          upgrade
          data-hook={BookingDetailsDataHooks.SLOT_DURATION_DROPDOWN}
          className={st(classes.commonDropDownStyle)}
          placeholder={bookingDetailsDurationDropDownText}
          options={selectableDurationsWithSubtitle}
          key={selectableDurationsKey}
          onChange={(duration) =>
            onOptionSelectedAction({
              duration: duration.id,
            })
          }
        />
      ) : null}
    </div>
  );
};
const BookingDetails: React.FC<BookingDetailsProps> = ({ viewModel }) => {
  const { isMobile, isRTL } = useEnvironment();

  const shouldShowPreferences = () => {
    return (
      selectableLocations.length > 1 ||
      selectableDurations.length > 1 ||
      selectableStaffMembers.length > 1
    );
  };
  const {
    selectableLocations,
    selectableDurations,
    selectableStaffMembers,
  } = viewModel;

  return (
    <div
      data-hook={BookingDetailsDataHooks.BOOKING_DETAILS_SELECTION_WRAPPER}
      className={st(classes.root, { isMobile, isRTL })}
    >
      <SlotDetails viewModel={viewModel} />
      {shouldShowPreferences() ? (
        <SlotPreferences viewModel={viewModel} />
      ) : null}
    </div>
  );
};
export default BookingDetails;
