// eslint-disable-next-line no-shadow
export enum BookingDetailsDataHooks {
  BOOKING_DETAILS_SELECTION_WRAPPER = 'booking-details-wrapper',
  SLOT_NAME = 'slot-name',
  SLOT_DETAILS = 'slot-details',
  SLOT_DATE_AND_TIME = 'slot-date-and-time',
  SLOT_LOCATION = 'slot-location',
  SLOT_DURATION = 'slot-duration',
  SLOT_DURATION_DROPDOWN = 'slot-duration-dropdown',
  SLOT_PLAN_TYPE = 'slot-plan-type',
  DEVIDER = 'devider',
  SLOT_STAFF_MEMBER = 'slot-staff-member',
  SLOT_STAFF_MEMBER_DROPDOWN = 'slot-staff-member-dropdown',
  SLOT_PREFERENCES_WRAPPER = 'slot-preferences-wrapper',
  SLOT_PREFERENCES_TITLE = 'slot-preferences-title',
  SLOT_LOCATION_DROPDOWN = 'slot-location-dropdown',
  SLOT_CLEAR_TITLE = 'slot-clear-title',
  VIDEO_CONFERENCE_BADGE = 'video-conference-badge',
}
