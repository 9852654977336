import React from 'react';
import {
  TimePickerStatus,
  TimePickerViewModel,
} from '../../ViewModel/timePickerViewModel/timePickerViewModel';
import { TimePickerDataHooks } from './TimePickerDataHooks';
import { Text } from 'wix-ui-tpa/Text';
import { classes, st } from './TimePicker.st.css';
import { AccessibilityHtmlTags } from '../../../../utils/AccessibilityHtmlTags.const';
import { useEnvironment } from 'yoshi-flow-editor';
import { Spinner } from 'wix-ui-tpa/Spinner';
import { SPINNER_TYPES } from 'wix-ui-tpa/dist/src/components/Spinner/types';
import NoAvailableSlots from './NoAvailableSlots/NoAvailableSlots';
import TimeSelection from './TimeSelection/TimeSelection';

export type TimePickerProps = {
  viewModel: TimePickerViewModel;
};

const TimePicker: React.FC<TimePickerProps> = ({ viewModel }) => {
  const {
    selectedDate,
    noAvailableSlotsViewModel,
    timeSelectionViewModel,
  } = viewModel;
  const { isMobile, isRTL } = useEnvironment();

  const getTimePickerLoader = () => (
    <div
      data-hook={TimePickerDataHooks.LOADER}
      className={st(classes.loader, {
        isMobile,
      })}
    >
      <Spinner diameter={24} isCentered={true} type={SPINNER_TYPES.slim} />
    </div>
  );

  const getTimePickerContentByStatus = () => {
    switch (viewModel.status) {
      case TimePickerStatus.LOADING:
        return getTimePickerLoader();
      case TimePickerStatus.NO_AVAILABLE_SLOTS_FOR_SELECTED_DATE:
        return <NoAvailableSlots viewModel={noAvailableSlotsViewModel} />;
      case TimePickerStatus.LOADED:
        return <TimeSelection viewModel={timeSelectionViewModel} />;
      default:
        return getTimePickerLoader();
    }
  };

  return (
    <div
      data-hook={TimePickerDataHooks.TIME_PICKER}
      className={st(classes.root, { isMobile, isRTL })}
    >
      <Text
        data-hook={TimePickerDataHooks.SELECTED_DATE}
        className={st(classes.selectedDate)}
        tagName={AccessibilityHtmlTags.Paragraph}
      >
        {selectedDate}
      </Text>
      {getTimePickerContentByStatus()}
    </div>
  );
};

export default TimePicker;
